import * as React from "react";

function RefreshIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 492.883 492.883"
      fill="#f45f34"
      {...props}
    >
      <path d="M122.941 374.241c-20.1-18.1-34.6-39.8-44.1-63.1-25.2-61.8-13.4-135.3 35.8-186l45.4 45.4c2.5 2.5 7 .7 7.6-3l24.8-162.3c.4-2.7-1.9-5-4.6-4.6l-162.4 24.8c-3.7.6-5.5 5.1-3 7.6l45.5 45.5c-75.1 76.8-87.9 192-38.6 282 14.8 27.1 35.3 51.9 61.4 72.7 44.4 35.3 99 52.2 153.2 51.1l10.2-66.7c-46.7 4-94.7-10.4-131.2-43.4zM424.941 414.341c75.1-76.8 87.9-192 38.6-282-14.8-27.1-35.3-51.9-61.4-72.7-44.4-35.3-99-52.2-153.2-51.1l-10.2 66.7c46.6-4 94.7 10.4 131.2 43.4 20.1 18.1 34.6 39.8 44.1 63.1 25.2 61.8 13.4 135.3-35.8 186l-45.4-45.4c-2.5-2.5-7-.7-7.6 3l-24.8 162.3c-.4 2.7 1.9 5 4.6 4.6l162.4-24.8c3.7-.6 5.4-5.1 3-7.6l-45.5-45.5z" />
    </svg>
  );
}

export default RefreshIcon;
